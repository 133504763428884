import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { BbcComponent} from './bbc/bbc.component';
import { ContactComponent} from './contact/contact.component';
import { DoulaComponent} from './doula/doula.component';
import { AssistantComponent} from './assistant/assistant.component';
import {MissingAssetSourceRootException} from '@angular-devkit/build-angular/src/utils';
import {ColorpickerComponent} from './colorpicker/colorpicker.component';
import {TrainingsComponent} from './trainings/trainings.component';
import {ServicesComponent} from './services/services.component';
import {ClasslistComponent} from './classes/classlist/classlist.component';

const routes: Routes = [
  {path: "", pathMatch: "full", redirectTo: "home"},
  {path: "bbc", pathMatch: "full", redirectTo: "classes"},
  {path: "home", component: HomeComponent},
  {path: "about", component: AboutComponent, data: { title: 'About'} },
  {path: "doula", component: DoulaComponent, data: { title: 'Doula'} },
  {path: "assistant", component: AssistantComponent, data: { title: 'Midwife Assistant'} },
  {path: "contact", component: ContactComponent, data: { title: 'Contact'} },
  {path: "services", component: ServicesComponent, data: { title: 'Services'} },
  {path: "classes", component: ClasslistComponent, data: { title: 'Classes'} },
  {path: "trainings", component: TrainingsComponent, data: { title: 'Training'} },
  {path: "test", component: ColorpickerComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
